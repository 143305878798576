import createReducer from '../helpers/createReducer';
import updateState from '../helpers/updateState';

const setCollection = (state, action) => updateState(state, { collection: action.collection });

const setQueryText = (state, action) => updateState(state, { queryText: action.queryText });

const setLocationId = (state, action) => updateState(state, { selectedLocationId: action.id });

const showMoreSearchResults = state => updateState(state, {
  resultsLimit: state.resultsLimit + 12,
});

const showGalleryMap = state => updateState(state, { showMap: true });

const hideGalleryMap = state => updateState(state, { showMap: false });

const incrementSearchKey = state => updateState(state, { searchKey: state.searchKey + 1 });

const defaultSearchState = {
  queryText: '',
  selectedLocationId: '',
  resultsLimit: 12,
  collection: [],
  showMap: false,
  searchKey: 0,
};

export default createReducer(defaultSearchState, {
  SET_COLLECTION: setCollection,
  SET_QUERY_TEXT: setQueryText,
  SET_LOCATION_ID: setLocationId,
  SHOW_MORE_SEARCH_RESULTS: showMoreSearchResults,
  SHOW_GALLERY_MAP: showGalleryMap,
  HIDE_GALLERY_MAP: hideGalleryMap,
  INCREMENT_SEARCH_KEY: incrementSearchKey,
});
