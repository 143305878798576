import React from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import createStore from '../src/state/createStore';

const store = createStore();

const providerWrapper = ({ element }) => <Provider store={store}>{element}</Provider>;

export default providerWrapper;

providerWrapper.propTypes = {
  element: PropTypes.node.isRequired,
};
