/* eslint max-len: 0 */

import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore as reduxCreateStore, applyMiddleware } from 'redux';
import multi from 'redux-multi';
import rootReducer from './reducers/rootReducer';

const createStore = () => reduxCreateStore(rootReducer, {}, composeWithDevTools(applyMiddleware(multi)));

export default createStore;
