import createReducer from '../helpers/createReducer';
import updateState from '../helpers/updateState';

const setMediaFilter = (state, action) => updateState(state, {
  mediaFilter: action.mediaFilter,
});

const defaultMediaState = { mediaFilter: '' };

export default createReducer(defaultMediaState, {
  SET_MEDIA_FILTER: setMediaFilter,
});
