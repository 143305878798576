import createReducer from '../helpers/createReducer';
import updateState from '../helpers/updateState';

const toggleMobileMenu = state => updateState(state, {
  mobileMenuVisible: !state.mobileMenuVisible,
});

const defaultMenuState = { mobileMenuVisible: false };

export default createReducer(defaultMenuState, {
  TOGGLE_MOBILE_MENU: toggleMobileMenu,
});
