// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/john/dev/bm-islamic-gallery-website/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-article-index-jsx": () => import("/Users/john/dev/bm-islamic-gallery-website/src/templates/article/index.jsx" /* webpackChunkName: "component---src-templates-article-index-jsx" */),
  "component---src-templates-object-index-jsx": () => import("/Users/john/dev/bm-islamic-gallery-website/src/templates/object/index.jsx" /* webpackChunkName: "component---src-templates-object-index-jsx" */),
  "component---src-templates-media-index-jsx": () => import("/Users/john/dev/bm-islamic-gallery-website/src/templates/media/index.jsx" /* webpackChunkName: "component---src-templates-media-index-jsx" */),
  "component---src-pages-404-jsx": () => import("/Users/john/dev/bm-islamic-gallery-website/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-albukhary-foundation-jsx": () => import("/Users/john/dev/bm-islamic-gallery-website/src/pages/albukhary-foundation.jsx" /* webpackChunkName: "component---src-pages-albukhary-foundation-jsx" */),
  "component---src-pages-collection-jsx": () => import("/Users/john/dev/bm-islamic-gallery-website/src/pages/collection.jsx" /* webpackChunkName: "component---src-pages-collection-jsx" */),
  "component---src-pages-index-jsx": () => import("/Users/john/dev/bm-islamic-gallery-website/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-landing-1-jsx": () => import("/Users/john/dev/bm-islamic-gallery-website/src/pages/landing/1.jsx" /* webpackChunkName: "component---src-pages-landing-1-jsx" */),
  "component---src-pages-landing-2-jsx": () => import("/Users/john/dev/bm-islamic-gallery-website/src/pages/landing/2.jsx" /* webpackChunkName: "component---src-pages-landing-2-jsx" */),
  "component---src-pages-media-jsx": () => import("/Users/john/dev/bm-islamic-gallery-website/src/pages/media.jsx" /* webpackChunkName: "component---src-pages-media-jsx" */)
}

exports.data = () => import("/Users/john/dev/bm-islamic-gallery-website/.cache/data.json")

