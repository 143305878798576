module.exports = [{
      plugin: require('/Users/john/dev/bm-islamic-gallery-website/node_modules/gatsby-plugin-offline/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/john/dev/bm-islamic-gallery-website/node_modules/gatsby-plugin-nprogress/gatsby-browser'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/john/dev/bm-islamic-gallery-website/node_modules/gatsby-plugin-google-analytics/gatsby-browser'),
      options: {"plugins":[],"trackingId":"UA-1005261-1","head":false},
    },{
      plugin: require('/Users/john/dev/bm-islamic-gallery-website/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
