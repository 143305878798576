import { combineReducers } from 'redux';
import menuReducer from './menuReducer';
import searchReducer from './searchReducer';
import mediaReducer from './mediaReducer';

export default combineReducers({
  menu: menuReducer,
  search: searchReducer,
  media: mediaReducer,
});
